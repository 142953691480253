.aa-Form {
  border-radius: 8px !important;
  border-width: 2px !important;
  border-color: #6366f1 !important;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

body {
  overflow-y: hidden;
}

html {
  scroll-behavior: smooth;
}
